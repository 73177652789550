
body {
  height: 100vh;
}

.App, section {
  text-align: center;
  background-color: #f7f5f3;
}

.container.content-section {
  min-height: 100vh;
}

h1, p, small, li, h1, h2, h3, h4, h5, h6, td, div, #backtotop {
  color: #343a40 !important;
}

#backtotop:hover {
  color:lightblue !important;
}

h1 {
  margin-bottom: 5%;
}

#header-body p {
  text-align: center;
}

p {
  text-align: left;
}

#aleximage {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 992px) {
  .personal-links {
    margin-bottom: 15px !important;
  }
  .comp-img {
    height: 20vmin !important;
  }
}

.navbar-brand {
  font-weight: bold;
  color: lightblue !important
}

.content-section h1 {
  margin-bottom: 2rem;
  font-weight: bold;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.hobby-images {
  height:15vmin;
  margin: 2rem;
}

.certification-image, .portfolio-image, .company-image, .carousel, .carousel img {
  height: 30vmin;
  width: 50vmin;
}

.carousel {
  margin: 0 auto;
}

#header-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

#top {
  height: 100vh;
}

.topnav-icons {
  margin: .5rem;
}

#header-p {
  margin-top: 5%;
}

.nav-link {
  color: #edebe9 !important;
  padding-left: 2rem !important;
  place-self: center;
}

.nav-item {
  place-self: center;
}

.nav-link:hover {
  color:lightblue !important;
}

#header-nav {
  width: 100%;
  position: fixed;
  z-index: 999;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.project-desc ul, .certification-desc ul {
  text-align: left;
}

.project, .certification {
  margin-bottom: 2rem !important;
}

.comfortable {
  background-color: lightgreen !important;
}

.some-exp {
  background-color: lightsalmon !important;
}

.nav-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  margin: .5rem;
}

.nav-icon:hover {
  cursor: pointer;
}

#contact {
  min-height: 30vh;
}

.timeline-jobdesc small {
  display: block;
  text-align: center;
}

.timeline-jobdesc ul {
  margin: 20px 0 20px 0;
}

.timeline-jobdesc li {
  text-align: left
}

.MuiTimelineItem-missingOppositeContent:before {
  -webkit-flex: 0 1!important;
  flex: 0 1!important;
}

.companyimg {
  width: 30vh;
}

#contact p {
  text-align: center;
}

